import {
    Container,
    CssBaseline,
    Box,
    Typography,
    TextField,
    Button,
    Breadcrumbs,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormHelperText,
    Modal,
    Switch,
    CircularProgress,
    Grid,
    Tooltip,
    IconButton,
} from "@mui/material";
import React, { useState, useRef, ChangeEvent } from "react";
import {Link, useNavigate} from "react-router-dom";
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import InfoIcon from '@mui/icons-material/Info';

const Generate = () => {
    const [fileName, setFileName] = useState("");
    const [fileContent, setFileContent] = useState<any>();
    const [topic, setTopic] = useState('');
    const [radioButtonValue, setRadioButtonValue] = useState("upload file");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [difficulty, setDifficulty] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [expertise, setExpertise] = React.useState('');
    const [errorFile, setErrorFile] = useState('');
    const [nameError, setNameError] = useState('');
    const [difficultyError, setDifficultyError] = useState('');
    const [expertiseError, setExpertiseError] = useState('');
    const [topicError, setTopicError] = useState('');
    const [amountError, setAmountError] = React.useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [questionListName, setQuestionListName] = useState('');
    const navigate = useNavigate();

    let timer: string | number | NodeJS.Timeout | undefined;
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 4, // Rounded corners
        boxShadow: 24,
        p: 4,
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const maxFileSize = 20 * 1024 * 1024; // 32 MB in bytes
        const file = e.target.files?.[0];

        if (file && file.size > maxFileSize) {
            setErrorFile('File size exceeds the limit (32MB). Please select a smaller file.');
            setFileName('');
            return;
        }
        setFileName(file ? file.name : "");
        setErrorFile('');
        if (file){setFileContent(file);}
    };

    const handleDifficultyChange = (event: SelectChangeEvent) => {
        setDifficulty(event.target.value);
        setDifficultyError('');
    };

    const handleExpertiseChange = (event: SelectChangeEvent) => {
        setExpertise(event.target.value);
        setExpertiseError('');
    };
    const handleRadioChange = (event: SelectChangeEvent) => {
        setRadioButtonValue(event.target.value);
        setDifficultyError('');
        setExpertiseError('');
        setTopicError('');
        setErrorFile("");
        setNameError('')
        setAmountError('');
    };

    const handleTopicChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setTopic(event.target.value);
        setTopicError(''); //
    };

    const handleNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setQuestionListName(event.target.value);
        setNameError(''); //
    };

    const handleAmountChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        const maxNumberOfQuestions = 15;
        const value = event.target.value;
        const numericValue = Number(value);

        if (value === '' || (numericValue >= 1 && numericValue <= maxNumberOfQuestions)) {
            setAmount(value);
            setAmountError('');
        } else {
            setAmountError('Please enter a number between 1 and 15');
        }
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        // Reset errors before checking again
        setErrorFile('');
        setDifficultyError('');
        setTopicError('');
        setExpertiseError('');
        setAmountError('');

        const errors = [];

        // Check if a difficulty is chosen
        if (!difficulty) {
            errors.push('Please select a difficulty.');
        }

        // Check if an expertise is entered when "Select Topic" is selected
        if (radioButtonValue === "select topic" && !expertise) {
            errors.push('Please select an expertise level.');
        }

        // Check if a questionlistname is chosen
        if (!questionListName) {
            errors.push('Please enter a question list name.');
        }

        // Check if the number of questions is chosen
        if (!amount) {
            errors.push('Please enter the number of questions to be generated.');
        }

        // Check if a file is selected when "Upload File" is selected
        if (radioButtonValue === "upload file" && !fileName) {
            errors.push('Please select a file.');
        }

        // Check if a topic is entered when "Select Topic" is selected
        if (radioButtonValue === "select topic" && !topic) {
            errors.push('Please enter a topic.');
        }

        if (errors.length > 0) {
            errors.forEach(error => {
                switch (error) {
                    case 'Please enter a question list name.':
                        setNameError(error);
                        break;
                    case 'Please select a file.':
                        setErrorFile(error);
                        break;
                    case 'Please select a difficulty.':
                        setDifficultyError(error);
                        break;
                    case 'Please select an expertise level.':
                        setExpertiseError(error);
                        break;
                    case 'Please enter a topic.':
                        setTopicError(error);
                        break;
                    case 'Please enter the number of questions to be generated.':
                        setAmountError(error);
                        break;
                    default:
                        break;
                }
            });
            return;
        }

        if (radioButtonValue === "upload file") {
            if (fileName) {
                console.log("file")
            }
        }
        else {
            if (topic) {
                console.log("topic")
            }
        }
        handleGenerate();
    };

    const handleGenerate = () => {
        let url = '';
        let body: any = null;
        let headers = {};

        if (radioButtonValue === "upload file") {
            url = `${process.env.REACT_APP_API_URL}/question-lists/files`;
            const queryParams = new URLSearchParams();

            // Add query parameters
            queryParams.append('questionListName', questionListName);
            queryParams.append('isPublic', isPublic.toString());
            queryParams.append('difficulty', difficulty);
            queryParams.append('amountOfQuestions', amount);

            url += '?' + queryParams.toString();

            const formData = new FormData();
            formData.append('file', fileContent);
            console.log("filecontent:", fileContent);
            body = formData;
            headers = {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        } else if (radioButtonValue === "select topic") {
            url = `${process.env.REACT_APP_API_URL}/question-lists/topic`;
            body = {
                questionListName: questionListName,
                isPublic: isPublic,
                topic: topic,
                preKnowledge: expertise,
                difficulty: difficulty,
                amountOfQuestions: amount,
            };
            body = JSON.stringify(body);
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        }

        fetch(url, {
            method: 'POST',
            headers: headers,
            body: body,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Handle successful response
                console.log('Generate successful:', data);
                setModalOpen(true);
                timer = setInterval(() => {checkQuestionsAvailable(data.id)}, 500);

            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the generation:', error);
            });
    };

    const checkQuestionsAvailable=(id : string) => {
        console.log("checking available");
        let url = `${process.env.REACT_APP_API_URL}/question-lists/${id}/questions`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the questions from the questionlist", data);
                if (data && data.length > 0) {
                    clearInterval(timer);
                    setModalOpen(false);
                    navigate(`/details?id=${id}`);
                }
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });
    }

    return (
        <>
            <Container maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb" sx={{ width: "100%", alignItems: "flex-end" }}>
                        <Link color="inherit" to={"/"}>
                            Home
                        </Link>
                        <Link
                            color="inherit"
                            to={"/dashboard"}                    >
                            Questions
                        </Link>
                        <Typography color="text.primary">AutoGenerate</Typography>
                    </Breadcrumbs>
                    <Typography variant="h4" sx={{ width: "100%", alignItems: "flex-end" }}>
                        Generate new questionlist
                    </Typography>


                    <form onSubmit={handleSubmit}>
                        <RadioGroup
                            row
                            aria-labelledby="generation-option"
                            name="generation-option"
                            value={radioButtonValue}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value="upload file" control={<Radio />} label="Upload File" />
                            <FormControlLabel value="select topic" control={<Radio />} label="Select Topic" />

                        </RadioGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <FormControl sx={{ mt: 1 }} error={!!difficultyError} fullWidth>

                                    <InputLabel id="select-difficulty-label">Difficulty</InputLabel>
                                    <Select
                                        labelId="select-difficulty-label"
                                        id="select-difficulty-selector"
                                        value={difficulty}
                                        onChange={handleDifficultyChange}
                                        label="Difficulty"
                                        sx={{ width: 200 }}
                                    >
                                        <MenuItem value={"EASY"}>Easy</MenuItem>
                                        <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                                        <MenuItem value={"HARD"}>Hard</MenuItem>
                                    </Select>
                                    {difficultyError && <FormHelperText>{difficultyError}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                {radioButtonValue === "select topic" && (
                                    <FormControl sx={{ mt: 1 }} error={!!expertiseError} fullWidth>

                                        <InputLabel id="select-expertise-label">Expertise</InputLabel>
                                        <Select
                                            labelId="select-expertise-label"
                                            id="select-expertise-selector"
                                            value={expertise}
                                            onChange={handleExpertiseChange}
                                            label="Expertise"
                                            sx={{ width: 200 }}
                                        >
                                            <MenuItem value={"NOVICE"}>Novice</MenuItem>
                                            <MenuItem value={"BEGINNER"}>Beginner</MenuItem>
                                            <MenuItem value={"BASIC"}>Basic</MenuItem>
                                            <MenuItem value={"INTERMEDIATE"}>Intermediate</MenuItem>
                                            <MenuItem value={"PROFICIENT"}>Proficient</MenuItem>
                                            <MenuItem value={"EXPERT"}>Expert</MenuItem>
                                        </Select>
                                        {expertiseError && <FormHelperText>{expertiseError}</FormHelperText>}
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item>
                                {radioButtonValue === "select topic" && (
                                    <Tooltip
                                        title={
                                            <div>
                                                <div><strong>Novice:</strong><br/>I know nothing about this subject<br/><br/>
                                                </div>
                                                <div><strong>Beginner:</strong><br/>I know almost nothing about
                                                    this subject, I know very little about it.<br/><br/></div>
                                                <div><strong>Basic:</strong><br/>I have some basic knowledge about
                                                    this subject, but no formal education or training. I know as much as
                                                    the average person about this topic.<br/><br/></div>
                                                <div><strong>Intermediate:</strong><br/>I am at an intermediate level, I
                                                    know more than an average person, but I am not involved in this
                                                    topic.<br/><br/></div>
                                                <div><strong>Proficient:</strong><br/>I have acquired a solid understanding
                                                    of this subject through consistent study, practice, and application.<br/><br/>
                                                </div>
                                                <div><strong>Expert:</strong><br/>I am highly knowledgeable about this
                                                    subject, having dedicated significant time and effort to mastering
                                                    its concepts.<br/><br/></div>
                                            </div>
                                        }
                                    >
                                        <IconButton aria-label="title-description">
                                            <InfoIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>

                        <TextField
                            sx={{mt: 2}}
                            margin="normal"
                            fullWidth
                            id="numberOfQuestions"
                            label="Number Of Questions"
                            name="numberOfQuestions"
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                            error={!!amountError}
                            helperText={amountError}
                        />

                        <TextField
                            sx={{mt: 2}}
                            margin="normal"
                            //required
                            fullWidth
                            id="questionListName"
                            label="Question List Name"
                            name="questionListName"
                            value={questionListName}
                            onChange={handleNameChange}
                            //onClick={handlePdfFileClick}
                            //inputProps={{ readOnly: true }}
                            error={!!nameError}
                            helperText={nameError}
                        />

                        {radioButtonValue === "upload file" && (
                            <>
                                <Button
                                    sx = {{mt:3}}
                                    aria-required
                                    fullWidth
                                    variant="outlined"
                                    component="label"
                                >
                                    Upload File
                                    <input
                                        type="file"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept=".pdf"
                                    />
                                </Button>

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="pdf-file"
                                    label="Select file"
                                    name="pdf-file"
                                    value={fileName}
                                    // onClick={handlePdfFileClick}
                                    inputProps={{ readOnly: true }}
                                    error={!!errorFile}
                                    helperText={errorFile}
                                />
                            </>

                        )}
                        {radioButtonValue === "select topic" && (
                            <FormControl required error={!!topicError} fullWidth>
                                <TextField
                                    sx = {{mb:2}}
                                    id="standard-basic"
                                    label="Select Topic"
                                    variant="standard"
                                    value={topic}
                                    onChange={handleTopicChange}
                                    error={!!topicError}
                                    helperText={topicError}
                                />
                            </FormControl>
                        )}

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">Make Public</Typography>
                            </Grid>
                            <Grid item>
                                <Switch
                                    checked={isPublic}
                                    onChange={(e) => setIsPublic(e.target.checked)}
                                />
                            </Grid>
                        </Grid>

                        <div>
                            <Button sx={{ mt: 2 }} type="submit" variant="contained" color="primary">
                                Autogenerate
                            </Button>
                        </div>

                    </form>

                    <CustomBottomNavigation value={2} />
                </Box>
            </Container>
            <Modal
                open={modalOpen}
                onClose={()=> {}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">Generating...</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Generate;