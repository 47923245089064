import React from 'react';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import SchoolIcon from '@mui/icons-material/School';
import Paper from '@mui/material/Paper';

interface BottomNavProps {
    value: number;
}

const CustomBottomNavigation: React.FC<BottomNavProps> = ({ value }) => {
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
            >
                <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} component={Link} to="/account"  />
                <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/" />
                <BottomNavigationAction label="Questions" icon={<ListIcon />} component={Link} to="/dashboard" />
                <BottomNavigationAction label="Progress Paths" icon={<SchoolIcon />} component={Link} to="/progression_paths" />
            </BottomNavigation>
        </Paper>
    );
};

export default CustomBottomNavigation;
