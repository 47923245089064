import { useLocation, useNavigate } from "react-router-dom";
import QuestionModal from "./QuestionModal";

interface MakeQuestionLogicProps {
    modalOpen: boolean;
    handleClose: () => void;
    question: string;
    setQuestion: React.Dispatch<React.SetStateAction<string>>;
    answer: string;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
    explanation: string;
    setExplanation: React.Dispatch<React.SetStateAction<string>>;
}


const MakeQuestionLogic: React.FC<MakeQuestionLogicProps> = ({ modalOpen, handleClose, question, setQuestion, answer, setAnswer, explanation, setExplanation }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const questionListId = searchParams.get('id');


    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_API_URL}/question-lists/${questionListId}/questions/`;
        const data = {
            id: "",
            questionListId: "",
            questionText: question,
            questionAnswerTrue: answer === "true",
            questionExplanation: explanation
        };
        console.log(data)
        console.log(JSON.stringify(data))
        console.log(url)
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Handle successful response
                console.log('creating question was successful', data);
                handleClose();
                setQuestion("");
                setExplanation("");
                navigate(`/details?id=${data.questionListId}`);

            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the login:', error);
            });
    }
    return (
        <QuestionModal
            handleClose={() => {
                setQuestion("");
                setExplanation("");
                handleClose()
            }}
            modalOpen={modalOpen}
            handleSubmit={handleSubmit}
            question={question}
            setQuestion={setQuestion}
            answer={answer}
            setAnswer={setAnswer}
            explanation={explanation}
            setExplanation={setExplanation}
            title="Create new question" />
    )
}

export default MakeQuestionLogic;