import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Generate from "./pages/Generate";
import Dashboard from "./pages/Dashboard";
import QuestionDetail from './pages/QuestionDetail';
import Account from "./pages/Account";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { jwtDecode } from "jwt-decode";
import ProgressionPath from './pages/ProgressionPath';
import ProgressionPathDetail from './pages/ProgressionPathDetail';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#2196f3', // Change primary color to blue
      main: '#000000', // Change primary color to blue
    },
    secondary: {
      main: '#f50057', // Change secondary color to pink
    },
  },
});

// Define checkTokenExpiration function
const checkTokenExpiration = () => {
  console.log("checking token");
  const token = localStorage.getItem("token")
  if (token && token !== "undefined") {
    const decodedToken: any = jwtDecode(token!);
    if (Date.now() >= decodedToken.exp * 1000) {
      console.log("token expired")
      // Token is expired
      localStorage.removeItem('token');
      window.location.href = '/login'; // Redirect to login page
    }
  }
};


function App() {
  useEffect(() => {
    // Set up interval for checking token expiration
    const interval = setInterval(checkTokenExpiration, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/generate" element={<Generate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/details" element={<QuestionDetail />} />
        <Route path="/account" element={<Account />} />
        <Route path="/progression_paths" element={<ProgressionPath />} />
        <Route path="/progression_paths/details" element={<ProgressionPathDetail />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
