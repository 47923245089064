import React from 'react';
import { Typography, CardContent, Card, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';




interface EmptyQuestionCardProps {
    handleClick: () => void;
}


const EmptyQuestionCard: React.FC<EmptyQuestionCardProps> = ({handleClick}) => {
    return (
        <Card sx={{
            bgcolor: "#f5f5f5", border: "2px dashed", display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
        }} variant="outlined" className="hoverEffect" onClick={handleClick} >
            <CardContent >
                <IconButton   sx={{ ml: 3 }}>
                    <AddIcon fontSize="large" />
                </IconButton>
                <Typography variant="body1" align="center">
                    Add Question
                </Typography>
            </CardContent>
        </Card>
    )
};

export default EmptyQuestionCard;
