import {
    Container,
    CssBaseline,
    Box,
    Typography,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    Popover,
    Breadcrumbs,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridToolbar } from '@mui/x-data-grid';
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import MakeManualModal from "../components/MakeManualModal";
import { Course } from "../interfaces/Course";
import {jwtDecode} from "jwt-decode";




const Dashboard = () => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [publicCourseList, setPublicCourseList] = useState<Course[]>([]);
    const [courseList, setCourseList] = useState<Course[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [authorNames, setAuthorNames] = useState<{ [key: string]: string }>({});
    const [loggedInUserId, setLoggedInUserId] = useState<string | null>(null);

    const navigate = useNavigate();


    useEffect(() => {
        const jwt = localStorage.getItem('token');
        if (!jwt || jwt === "undefined") {
            navigate('/login'); // Redirect to login page if 'jwt' is not found
        }
        else {
            const decodedToken = jwtDecode(jwt); // Use jwtDecode or any other method to decode the JWT token
            setLoggedInUserId(decodedToken.sub || null);
        }
    }, [navigate]); // Depend on history to ensure the effect runs when the component mounts

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleManualClick = () => {
        setModalOpen(true);
        handleClose();
    };

    const postSubscribe = (url : string, id : number) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({ questionListId: id }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            })
            .then(data => {
                // Handle successful response
                console.log('(un)subscription successful:');
                window.location.reload();
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with (un)subscribing:', error);
            });
    };

    const handleSubscribe = (id: number) => {
        const url = process.env.REACT_APP_API_URL + '/question-lists/' + id + '/subscribe';
        postSubscribe(url, id);
    };

    const handleUnsubscribe = (id: number) => {
        const url = process.env.REACT_APP_API_URL + '/question-lists/' + id + '/unsubscribe';
        postSubscribe(url, id);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const columns: GridColDef[] = [
        {
            field: 'questionListName',
            width: 500,
            sortable: true,
            align: 'left', // Align text to the left
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Name'}
                </strong>
              ),

        },
        {
            field: 'createdById',
            width: 150,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Author'}
                </strong>
              ),
            //Make the style the same as the other cells in the datagrid
            renderCell: (params) => (
                <Typography style={{ fontSize: 'inherit', fontFamily: 'inherit', fontWeight: 'inherit', lineHeight: 'inherit' }}>
                    {authorNames[params.value]}
                </Typography>
            ),
        },
        {
            field: 'createdOn',
            headerName: 'Creation date',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Creation date'}
                </strong>
              ),
            width: 300,
            sortable: true,
            align: 'left',
            //Make the date column more humanly readable
            renderCell: (params) => (
                <Typography style={{ fontSize: 'inherit', fontFamily: 'inherit', fontWeight: 'inherit', lineHeight: 'inherit' }}>
                    {new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    }).format(new Date(params.value))}
                </Typography>
            ),

        },
    ];

    const ownColumns: GridColDef[] = [...columns, //reuse definition of columns
        {
            field: 'unsubscribe',
            headerName: 'Unsubscribe',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Unsubscribe'}
                </strong>
              ),
            width: 200,
            sortable: true,

            renderCell: (params) => {
                // Check if the author of the question list is not the logged-in user
                const isNotLoggedInUserQuestionList = params.row.createdById !== loggedInUserId;

                // Render the Unsubscribe button if the conditions are met
                if (isNotLoggedInUserQuestionList) {
                    return (
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation(); // Otherwise you would automatically go to the question details page
                            handleUnsubscribe(params.row.id);
                        }}>
                            Unsubscribe
                        </Button>
                    );
                } else {
                    return null; // Render nothing if the conditions are not met
                }
            },
            align: 'left',

        },
    ];


    const publicColumns: GridColDef[] = [...columns, //reuse definition of columns
        {
            field: 'subscribe',
            headerName: 'Subscribe',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Subscribe'}
                </strong>
              ),
            width: 200,
            sortable: true,
            renderCell: (params) => {
                // Check if the author of the question list is not the logged-in user
                const isNotLoggedInUserQuestionList = params.row.createdById !== loggedInUserId;

                // Render the subscribe button if the conditions are met
                if (isNotLoggedInUserQuestionList) {
                    return (
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation(); // Otherwise you would automatically go to the question details page
                            handleSubscribe(params.row.id);
                        }}>
                            Subscribe
                        </Button>
                    );
                } else {
                    return null; // Render nothing if the conditions are not met
                }
            },
            align: 'left',

        },
    ];

    const handleRowClick = (params: any) => {
        console.log("Clicked row data:", params.row);
        // Redirect to detail page with the ID of the clicked row
        navigate(`/details?id=${params.row.id}`);
    };


    const urlPublic = process.env.REACT_APP_API_URL + '/question-lists/public';
    const url = process.env.REACT_APP_API_URL + '/question-lists/';

    useEffect(() => {
        //Fetch all question lists
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the data", data);
                setCourseList(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });

        //Fetch public question lists
        fetch(urlPublic, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the public data", data);
                setPublicCourseList(data);
                // setPublicCourseList(courseListTest)

            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of public question lists:', error);
            });
    }, [url, urlPublic]);

    useEffect(() => {
        // Fetch author names for all course entries
        const authorIds = Array.from(new Set([
            ...courseList.map(course => course.createdById),
            ...publicCourseList.map(course => course.createdById)
        ]));
        const uniqueAuthorIds = Array.from(new Set(authorIds)); // Get unique author IDs
        console.log(uniqueAuthorIds)
        const fetchAuthorNames = async () => {
            for (const authorId of uniqueAuthorIds) {
                const urlUser = `${process.env.REACT_APP_API_URL}/users/${authorId}`;
                fetch(urlUser, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log("this is the user data", data);
                        setAuthorNames(prevState => ({
                            ...prevState,
                            [authorId]: data.firstName + ' ' + data.lastName
                        }));
                    })
                    .catch(error => {
                        // Handle error
                        console.error('There was a problem with the retrieval of the user data:', error);
                    });
            }
        };
        fetchAuthorNames();
    }, [courseList,publicCourseList]);

    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    mt: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Typography color="text.primary">Questions</Typography>
                </Breadcrumbs>
                <Grid container justifyContent="space-between">

                    <Typography variant="h3">Dashboard</Typography>
                    <Button
                        variant="contained"
                        size="medium"
                        sx={{ marginRight: 1 }}
                        startIcon={<AddIcon />}
                        onClick={handleClick}
                    >
                        Add new questionlist
                    </Button>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <List>
                            <ListItem button component={Link} to="/generate" onClick={handleClose}>
                                <ListItemText primary="Autogenerate" />
                            </ListItem>
                            <ListItem button onClick={handleManualClick}>
                                <ListItemText primary="Make manually" />
                            </ListItem>
                        </List>
                    </Popover>
                </Grid>

                <MakeManualModal modalOpen={modalOpen} handleClose={() => setModalOpen(false)}></MakeManualModal>

                <Typography variant="h4" sx={{ mt: 5, width: '100%' }}>My Question Lists</Typography>

                <Box sx={{ mt: 2, width: '100%' }}>

                    {courseList.length > 0 ? (
                        <DataGrid
                            rows={courseList}
                            columns={ownColumns}

                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            slots={{ toolbar: GridToolbar }}

                        />
                    ) : (
                        <p>It looks kind of empty here. Quickly subscribe to a course or make one.</p>
                    )}
                </Box>

                <Typography variant="h4" sx={{ mt: 5, width: '100%' }}>Public Question Lists</Typography>

                <Box sx={{ mt: 2, width: '100%' }}>

                    <DataGrid
                        sx={{mb:7}}
                        rows={publicCourseList}
                        columns={publicColumns}
                        onRowClick={handleRowClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbar }}

                    />
                </Box>


                <CustomBottomNavigation value={2} />
            </Box>
        </Container>
    );
};

export default Dashboard;
