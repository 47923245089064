import React from 'react';
import { Typography, Button, CardContent, Card, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';



interface Props {
    questionlistname:string;
    date:Date;
    author:string;
    id:string;
    onAdd:(questionlistId:string)=>void;
}

const QuestionListCard: React.FC<Props> = ({questionlistname,date,author,id,onAdd}) => {
    return (
        <Card sx={{ borderRadius: 2, boxShadow: 1 }} variant='outlined'>
            <CardContent >
                <div style={{ position: 'relative' }}>
                    <Button onClick={()=>{onAdd(id)}} size="large" sx={{ marginLeft: 1, position: 'absolute', top: 15, right: 0 }}><AddIcon /></Button>
                    {/* <Button variant="outline  d" size="small" sx={{ marginLeft: 1, position: 'absolute', top: 80, right: 0 }} endIcon={<AddIcon />}>Edit</Button> */}
                </div>
                <Typography variant="h5" component="div" sx={{ mr: 25 }}>
                    {questionlistname}
                </Typography>
                <Box>
                    <Typography
                        sx={{
                            display: "inline-block",
                            borderRadius: 1
                        }}
                    >
                        Created on {date.toDateString()} by {author}
                    </Typography>
                </Box>
            </CardContent>

        </Card>
    );
};

export default QuestionListCard;
