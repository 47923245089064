import {
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import { jwtDecode } from "jwt-decode";


const Account = () => {

    const navigate = useNavigate();

    const handleOnLogOut=()=>{
        localStorage.removeItem('token');
        navigate('/login');

    }
    

    let decodedToken :any = '';
    let token = localStorage.getItem("token");
    if (!token || token === "undefined") {
        handleOnLogOut();
    }

    if (token && token !== "undefined") {
        decodedToken = jwtDecode(token);
        console.log(decodedToken)
    }

    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    mt: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ bgcolor: "primary.light", width: 100, height: 100, fontSize: 28 }}></Avatar>

                <Typography variant="h6">{decodedToken.firstName +" "+decodedToken.lastName}</Typography>

                <Typography variant="h5">{decodedToken.email}</Typography>

                <Button sx={{ mt: 20 }} variant="contained" color="primary" onClick={handleOnLogOut}>
                    Log out
                </Button>

                <CustomBottomNavigation value={0} />
            </Box>
        </Container>
    );
};

export default Account;
