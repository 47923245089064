import { Modal, Box, Typography, TextField, Switch, Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ManeManualModalProps {
    modalOpen: boolean;
    handleClose: () => void;
}


const ManeManualModal: React.FC<ManeManualModalProps> = ({ modalOpen, handleClose }) => {
    const [questionListName, setQuestionListName] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    let decodedToken = '';
    let token  = localStorage.getItem("token");
    if(token && token !== "undefined"){
        decodedToken = jwtDecode(token);
    }
    console.log(decodedToken)
    const navigate = useNavigate();

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 4, // Rounded corners
        boxShadow: 24,
        p: 4,
    };


    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const url = process.env.REACT_APP_API_URL + '/question-lists/';
        const data = {
            id: "",
            questionIds: [],
            questionListName: questionListName,
            createdById: decodedToken.sub,
            createdOn: new Date(),
            isPublic: isPublic
        };
        console.log(data)
        console.log(JSON.stringify(data))

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Handle successful response
                console.log('creating Questionlist was successful', data);

                navigate(`/details?id=${data.id}`);

            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the login:', error);
            });
    }
    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">Create New Question List</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Question List Name"
                        value={questionListName}
                        onChange={(e) => setQuestionListName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <Typography>Make Public</Typography>
                    <Switch
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                    </Box>
                </form>
            </Box>
        </Modal>)
}

export default ManeManualModal;