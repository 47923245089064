import { useLocation } from "react-router-dom";
import QuestionModal from "./QuestionModal";
import { Question } from "../interfaces/Question";

interface EditQuestionLogicProps {
    modalOpen: boolean;
    handleClose: () => void;
    question: string;
    setQuestion: React.Dispatch<React.SetStateAction<string>>;
    answer: string;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
    explanation: string;
    setExplanation: React.Dispatch<React.SetStateAction<string>>;
    questionId: string;
    setQuestions: React.Dispatch<React.SetStateAction<Question[]>>

}


const EditQuestionLogic: React.FC<EditQuestionLogicProps> = ({ modalOpen, handleClose, question, setQuestion, answer, setAnswer, explanation, setExplanation, questionId, setQuestions }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const questionListId = searchParams.get('id');

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_API_URL}/question-lists/${questionListId}/questions/${questionId}`;
        const data = {
            id: questionId,
            questionListId: "",
            questionText: question,
            questionAnswerTrue: answer === "true",
            questionExplanation: explanation
        };
        console.log(data)
        console.log(JSON.stringify(data))
        console.log(url)
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Handle successful response
                setQuestions(prevQuestions => prevQuestions.map(q => q.id === questionId ? data : q));
                console.log('edditing question was successful', data);
                handleClose();
                setQuestion("");
                setExplanation("");
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the login:', error);
            });
    }
    return (
        <QuestionModal
            handleClose={() => {
                setQuestion("");
                setExplanation("");
                handleClose()
            }}
            modalOpen={modalOpen}
            handleSubmit={handleSubmit}
            question={question}
            setQuestion={setQuestion}
            answer={answer}
            setAnswer={setAnswer}
            explanation={explanation}
            setExplanation={setExplanation}
            title="Edit question" />
    )
}

export default EditQuestionLogic;