import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from "@mui/material/Paper";



const CustomBottomNavigationLoggedOut = () => {
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
            showLabels
            value={0}
        >
            <BottomNavigationAction label="login" icon={<AccountCircleIcon />} component={Link} to="/login" />
            </BottomNavigation>
        </Paper>
    );
};

export default CustomBottomNavigationLoggedOut;
