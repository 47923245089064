import { Modal, Box, Typography, TextField, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

interface QuestionModalProps {
    modalOpen: boolean;
    handleClose: () => void;
    handleSubmit: (event: { preventDefault: () => void; }) => void;
    question: string;
    setQuestion: React.Dispatch<React.SetStateAction<string>>;
    answer: string;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
    explanation: string;
    setExplanation: React.Dispatch<React.SetStateAction<string>>;
    title: string
}


const QuestionModal: React.FC<QuestionModalProps> = ({ modalOpen, handleClose, handleSubmit, question, setQuestion, answer, setAnswer, explanation, setExplanation, title }) => {

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 4, // Rounded corners
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">{title}</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Answer</FormLabel>
                        <RadioGroup
                            aria-label="answer"
                            name="answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            row
                        >
                            <FormControlLabel value="true" control={<Radio />} label="True" />
                            <FormControlLabel value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        label="Explanation"
                        value={explanation}
                        onChange={(e) => setExplanation(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                    </Box>
                </form>
            </Box>
        </Modal>

    )
}

export default QuestionModal;