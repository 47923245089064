import {
    Box,
    Breadcrumbs, Button,
    Container,
    CssBaseline,
    Grid,
    Modal,
    Typography
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { Question } from "../interfaces/Question";
import { useEffect, useState } from "react";
import QuestionListCard from "../components/QuestionListCard";
import { Course } from "../interfaces/Course";
import Level from "../components/Level";
import { ProgressionPathEntrieInterface } from "../interfaces/ProgressionPathEntrieInterface";
import { jwtDecode } from "jwt-decode";


const ProgressionPathDetail = () => {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [entries, setEntries] = useState<ProgressionPathEntrieInterface[]>([]);
    const [progressionPathName, setProgressionPathName] = useState("loading...");
    const [publicQuestionList, setPublicQuestionList] = useState<Course[]>([]);
    const [selectedQuestionList, setSelectedQuestionList] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);
    const [numberCompleted, setNumberCompleted] = useState(0);
    const [authorNames, setAuthorNames] = useState<{ [key: string]: string }>({});
    const [subscribed, setSubscribed] = useState(false);

    let decodedToken = '';
    let token = localStorage.getItem("token");
    if (token && token !== "undefined") {
        decodedToken = jwtDecode(token!);
    }
    const location = useLocation();
    const navigate = useNavigate();

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: 4,
        maxHeight: 900,
        overflow: "auto",
    };

    const getNumberOfCompletedEntries = () => {
        const searchParams = new URLSearchParams(location.search);
        const progressionPathId = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/progression-paths/${progressionPathId}/entries/completed`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the completed entries", data);
                setNumberCompleted(data.length);
                getAllEntries();
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the available entries:', error);
            });
    }

    const getPublicQuestionLists = () => {
        fetch(process.env.REACT_APP_API_URL + '/question-lists/public', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the public data", data);
                setPublicQuestionList(data);
                fetchAuthorNames();
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of public question lists:', error);
            });
    }

    const getProgressionPathDetails = () => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/progression-paths/${id}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the details of the progressionpath", data);
                setProgressionPathName(data.name);
                if (data.creatorId === decodedToken.sub) {
                    setAllowEdit(true);
                }
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });
    }

    const fetchAuthorNames = async () => {
        const authorIds = Array.from(new Set([
            ...publicQuestionList.map(course => course.createdById)
        ]));
        const uniqueAuthorIds = Array.from(new Set(authorIds)); // Get unique author IDs
        console.log(uniqueAuthorIds)
        for (const authorId of uniqueAuthorIds) {
            const urlUser = `${process.env.REACT_APP_API_URL}/users/${authorId}`;
            fetch(urlUser, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("this is the user data", data);
                    setAuthorNames(prevState => ({
                        ...prevState,
                        [authorId]: data.firstName + ' ' + data.lastName
                    }));
                })
                .catch(error => {
                    // Handle error
                    console.error('There was a problem with the retrieval of the user data:', error);
                });
        }
    };

    useEffect(() => {
        const jwt = localStorage.getItem('token');
        if (!jwt || jwt === "undefined") {
            navigate('/login'); // Redirect to login page if 'jwt' is not found
        }
        const searchParams = new URLSearchParams(location.search);
        const subscribed = searchParams.get('Subscribed');
        if (subscribed) {
            setSubscribed(subscribed === 'true');
        }
        getPublicQuestionLists();
        getProgressionPathDetails();
        getNumberOfCompletedEntries();
    }, [location, navigate]);

    const getAllEntries = () => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/progression-paths/${id}/entries`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the entries from the progressionPath", data);
                setEntries(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the entries:', error);
            });
    }

    const getSelectedQuestions = (questionListId: string) => {
        //When I click on a certain question list in my progression path, I want the questions of the questionlist to appear.

        const url = `${process.env.REACT_APP_API_URL}/question-lists/${questionListId}/questions`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the questions from the selected questionlist", data);
                setQuestions(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });
    };

    const handleClick = (questionListId: string, questionListName: string) => {
        setSelectedQuestionList(questionListName);
        getSelectedQuestions(questionListId);
    };

    const handleModalAdd = (questionListId: any) => {
        setModalOpen(false);
        const parentEntry = entries.find(entry => entry.parentEntryId === null);

        //this function is called when I press the  + icon to add a questionlist to my progression path
        console.log(questionListId)
        const searchParams = new URLSearchParams(location.search);
        const progresspathId = searchParams.get('id');
        //first add nieuw questionlist
        const url = `${process.env.REACT_APP_API_URL}/progression-paths/${progresspathId}/entries`;
        const data = {
            id: "",
            parentEntryId: "",
            childEntryId: parentEntry?.id,
            questionListId: questionListId,
        };
        console.log(data)
        console.log(JSON.stringify(data))

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("new entry added:", data);
                getAllEntries();
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with adding the question list:', error);
            });
    }

    const columns: GridColDef<(typeof questions)[number]>[] = [
        {
            field: 'questionText',
            headerName: 'Question',
            width: 680
        },
        {
            field: 'questionAnswerTrue',
            headerName: 'Answer',
            width: 60

        }
    ];

    const listItems = publicQuestionList.map((courseItem) =>
        <Grid item key={courseItem.id} xs={12}>
            <QuestionListCard onAdd={handleModalAdd} questionlistname={courseItem.questionListName} date={new Date(courseItem.createdOn)} author={authorNames[courseItem.createdById]} id={courseItem.id} />
        </Grid>
    );

    // Function to recursively find child entries and build the sorted list
    const buildSortedList = (): ProgressionPathEntrieInterface[] => {
        const sortedList: ProgressionPathEntrieInterface[] = [];

        const root = entries.find(entry => entry.childEntryId === null);
        if (root) {
            sortedList.push(root);
            let childId = root.parentEntryId;
            let childElement = entries.find(entry => entry.id === childId);
            while (childElement) {
                sortedList.push(childElement);
                childId = childElement.parentEntryId;
                childElement = entries.find(entry => entry.id === childId);
            }
        }
        return sortedList;
    };

    const handleDeleteEntry = (entryId: string) => {
        const searchParams = new URLSearchParams(location.search);
        const progressionPathId = searchParams.get('id');
        if (window.confirm("Are you sure you want to delete this questionList from the ProgressionPath?")) {
            // Proceed with delete action
            const url = `${process.env.REACT_APP_API_URL}/progression-paths/${progressionPathId}/entries/${entryId}`;
            console.log(url)
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    getAllEntries();
                    console.log('deleting question was successful');
                })
                .catch(error => {
                    // Handle error
                    console.error('There was a problem with the deletion:', error);
                });
        }
    }

    const handleDelete = () => {
        const isConfirmed = window.confirm('Are you sure you want to delete this question list?');
        if (isConfirmed) {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id');
            const url = `${process.env.REACT_APP_API_URL}/progression-paths/${id}`;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    console.log('Question list delete succesfully');
                    navigate('/progression_paths');
                })
                .catch(error => {
                    // Handle error
                    console.error('There was a problem with the retrieval of public question lists:', error);
                });
        }
    };

    const sortedEntrieList = buildSortedList().map((entry, index) => {
        if (!subscribed) {
            return (<Level key={entry.id} allowEdit={allowEdit} handleClick={handleClick} icon="public" questionListId={entry.questionListId} handleDelete={() => { handleDeleteEntry(entry.id) }} />
            );
        }
        if (index < numberCompleted) {
            return (<Level key={entry.id} allowEdit={allowEdit} handleClick={handleClick} icon="check" questionListId={entry.questionListId} handleDelete={() => { handleDeleteEntry(entry.id) }} />
            );
        }
        if (index === numberCompleted) {
            return (<Level key={entry.id} allowEdit={allowEdit} handleClick={handleClick} icon="play" questionListId={entry.questionListId} handleDelete={() => { handleDeleteEntry(entry.id) }} />
            );
        }
        else {
            return (<Level key={entry.id} allowEdit={allowEdit} handleClick={handleClick} icon="lock" questionListId={entry.questionListId} handleDelete={() => { handleDeleteEntry(entry.id) }} />
            )
        }
    }
    );


    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    mt: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    mb: 10
                }}
            >
                <Breadcrumbs aria-label="breadcrumb" sx={{ width: "100%", alignItems: "flex-end" }}>
                    <Link color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Link
                        color="inherit"
                        to={"/progression_paths"}>
                        ProgressionPaths
                    </Link>
                    <Typography color="text.primary">Details</Typography>
                </Breadcrumbs>
                <Grid container alignItems="center" justifyContent="space-between">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3">{progressionPathName}</Typography>
                    </div>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        style={{ backgroundColor: 'red', color: 'white', display: allowEdit ? 'block' : 'none' }}
                    >
                        Delete progression path
                    </Button>
                </Grid>
                {subscribed && (<LinearProgressWithLabel value={numberCompleted / entries.length} />)}
                <Box sx={{ display: 'flex', width: '100%', mt: 5 }}>
                    <Box sx={{ flex: '1 1 40%' }}>
                        <Box sx={{ position: "relative", mb: -0.3 }}>
                            <Box
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    left: '24px',
                                    top: 0,
                                    bottom: 0,
                                    width: '8px',
                                    backgroundColor: '#7a7a7a',
                                    borderRadius: '9999px',
                                }}
                            />
                            {sortedEntrieList}
                            {allowEdit && <Level allowEdit={allowEdit} handleClick={() => { setModalOpen(true) }} icon="add" questionListId="" handleDelete={() => { }} />}

                        </Box>
                    </Box>

                    <Box sx={{ flex: '1 1 60%' }}>
                        {selectedQuestionList !== "" && <Box>
                            <Typography variant="h5">{selectedQuestionList}</Typography>
                            <DataGrid
                                rows={questions}
                                columns={columns}
                                disableRowSelectionOnClick
                            />
                        </Box>}
                    </Box>
                </Box>
                <CustomBottomNavigation value={3} />
            </Box>
            <Modal
                open={modalOpen}
                onClose={() => { setModalOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h4" component="h4">Add a questionlist</Typography>
                    <Grid container spacing={2}>
                        {listItems}
                    </Grid>
                </Box>
            </Modal>
        </Container>
    );
}

export default ProgressionPathDetail