import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Avatar, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ListIcon from "@mui/icons-material/List";
interface LevelProps {
    icon: string;
    questionListId: string;
    handleClick: (id: string,text: string) => void;
    handleDelete:()=>void;
    allowEdit:boolean;
}

const Level: React.FC<LevelProps> = ({ icon, questionListId, handleClick, handleDelete,allowEdit }) => {
    const [questionListName, setQuestionListName] = useState("");



    const getQuestionListName = () => {

        if (questionListId === "") {
            setQuestionListName("add question list");
        }
        else {
            const url = `${process.env.REACT_APP_API_URL}/question-lists/${questionListId}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("these are the details from the questionlist", data);
                    setQuestionListName(data.questionListName)
                })
                .catch(error => {
                    // Handle error
                    console.error('There was a problem with the retrieval of the question lists:', error);
                });
        }
    }
    useEffect(() => {
        getQuestionListName()
    }, []);

    let subtext ="";

    const renderIcon = () => {
        switch (icon) {
            case 'lock':
                subtext = "Locked"
                return <LockIcon />;
            case 'play':
                subtext = "Ready to play in Game"
                return <PlayArrowIcon />;
            case 'check':
                subtext = "Completed"
                return <CheckIcon />;
            case 'public':
                return <ListIcon />;
            default:
                return <AddIcon />;
        }
    };

    const avatarOpacity = icon === 'lock' ? 0.5 : 1;

    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Prevent the click event from bubbling up to the parent container
        event.stopPropagation();
        handleDelete();
    };


    return (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', mb: 4, opacity: avatarOpacity, cursor: 'pointer' }} onClick={() => { handleClick(questionListId,questionListName); }}>
            <Avatar sx={{ bgcolor: "primary.light", width: 56, height: 56, marginRight: 2, }}>
                {renderIcon()}
            </Avatar>
            {(questionListId !== "" && allowEdit) && <IconButton
                size='small'
                aria-label="delete"
                onClick={handleDeleteClick} // Handle delete icon click
                sx={{position: 'absolute',top: -12, left: -12  }}
            >
                <ClearIcon sx={{ fontSize: 20 }} />
            </IconButton>}
            <Box>
                <Typography variant="h6">{questionListName}</Typography>
                <Typography variant="body1">{subtext}</Typography>
            </Box>
            
        </Box>
    );
}
export default Level;