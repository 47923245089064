import {Box, Container, CssBaseline, Typography} from "@mui/material";
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import CustomBottomNavigationLoggedOut from '../components/CustomBottomNavigationLoggedOut';


const Home = () => {

    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    mt: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >

                <Typography variant="h1"> <b>Brain Run</b></Typography>
                <div>
                    <Typography paragraph={true}>
                        <b>Brain-run </b>is an app designed especially for students that want to pass their exams without neglecting the need for physical activity.<br/>
                        Brain-run makes it possible to study (Brain) while being physically active (run).<br/>
                        This double performance is realised through an interactive game that adds a fun part to both studying and running.
                    </Typography>
                    <Typography paragraph={true}><b>Brain-what ?
                    </b></Typography>
                    <Typography paragraph={true}>
                        We offer access to questionlists that can be general or extracted from specific course-notes.<br/>
                        For the latter an AI-engine will do the magic although it's also possible to add your self-produced questionnaires.<br/>
                        Whilst playing the game, questions will pop up (as a form of obstacle).<br/>
                        Answering the questions (true/false) can be done by simple hand-raising (left/right).<br/>
                        That way it's not necessary to interrupt running for answering a question.
                    </Typography>

                    <Typography paragraph={true}><b>Run-what?


                    </b></Typography>

                    <Typography paragraph={true}>
                        While running yourself on a treadmill, a virtual character performs a similar action on the screen. <br/>
                        The speed of the character depends on the velocity of the treadmill.<br/>
                        Rewards can be gained by overcoming obstacles, and your own physical performance (speed and incline of the treadmill).
                    </Typography>

                    <Typography paragraph={true}>If you feel like having question lists created and no longer want to struggle with the time trade-off
                        studying/running: <a href="/register">subscribe here.</a>
                    </Typography>


                </div>
                {localStorage.getItem("token") ? <CustomBottomNavigation value={1} /> : <CustomBottomNavigationLoggedOut />

                }
            </Box>
        </Container>
    )
}

export default Home