import {
    Container,
    CssBaseline,
    Box,
    Typography,
    Button,
    Grid,
    Breadcrumbs,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridToolbar } from '@mui/x-data-grid';
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import MakeProgressionPathModal from "../components/MakeProgressionPathModal";
import { ProgressionPathInterface } from "../interfaces/ProgressionPathInterface";
import {jwtDecode} from "jwt-decode";




const ProgressionPath = () => {

    const [pathList, setPathList] = useState<ProgressionPathInterface[]>([]);
    const [publicPathList, setPublicPathList] = useState<ProgressionPathInterface[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [authorNames, setAuthorNames] = useState<{ [key: string]: string }>({});
    const [loggedInUserId, setLoggedInUserId] = useState<string | null>(null);

    const navigate = useNavigate();


    useEffect(() => {
        const jwt = localStorage.getItem('token');
        if (!jwt || jwt === "undefined") {
            navigate('/login'); // Redirect to login page if 'jwt' is not found
        } else {
            const decodedToken = jwtDecode(jwt); // Use jwtDecode or any other method to decode the JWT token
            setLoggedInUserId(decodedToken.sub || null);
        }
    }, [navigate]); // Depend on history to ensure the effect runs when the component mounts

    const handleManualClick = () => {
        setModalOpen(true);
    };

    const postSubscribe = (url : string, id : number) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({ progressionPathId: id }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            })
            .then(data => {
                // Handle successful response
                console.log('(un)subscription successful:');
                fetchSubscribedPaths(subscribedUrl);
                fetchPublicPaths(publicUrl);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with (un)subscribing:', error);
            });
    };

    const handleSubscribe = (id: number) => {
        const url = process.env.REACT_APP_API_URL + '/progression-paths/' + id + '/subscribe';
        postSubscribe(url, id);
    };

    const handleUnsubscribe = (id: number) => {
        const url = process.env.REACT_APP_API_URL + '/progression-paths/' + id + '/unsubscribe';
        postSubscribe(url, id);
    };

    const fetchPublicPaths = (url: string) => {
        //Fetch all progression paths
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the data", data);
                setPublicPathList(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the public progression paths:', error);
            });
    };

    const fetchSubscribedPaths = (url: string) => {
        //Fetch subscribed progression paths
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the data", data);
                setPathList(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the subscribed progression paths:', error);
            });
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            width: 500,
            sortable: true,
            align: 'left', // Align text to the left
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Name'}
                </strong>
              ),
        },
        {
            field: 'creatorId',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                  {'Author'}
                </strong>
              ),
            width: 150,
            sortable: true,
            //Make the style the same as the other cells in the datagrid
            renderCell: (params) => (
                <Typography style={{ fontSize: 'inherit', fontFamily: 'inherit', fontWeight: 'inherit', lineHeight: 'inherit' }}>
                    {authorNames[params.value]}
                </Typography>
            ),
        },

    ];

    const ownColumns: GridColDef[] = [...columns, //reuse definition of columns
        {
            field: 'unsubscribe',
            headerName: 'Unsubscribe',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Unsubscribe'}
                </strong>
            ),
            width: 200,
            sortable: true,

            renderCell: (params) => {
                // Check if the author of the question list is not the logged-in user
                const isNotLoggedInUserPath = params.row.creatorId !== loggedInUserId;

                // Render the Unsubscribe button if the conditions are met
                if (isNotLoggedInUserPath) {
                    return (
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation(); // Otherwise you would automatically go to the path details page
                            handleUnsubscribe(params.row.id);
                        }}>
                            Unsubscribe
                        </Button>
                    );
                } else {
                    return null; // Render nothing if the conditions are not met
                }
            },
            align: 'left',

        },
    ];

    const publicColumns: GridColDef[] = [...columns, //reuse definition of columns
        {
            field: 'subscribe',
            headerName: 'Subscribe',
            renderHeader: (params: GridColumnHeaderParams) => (
                <strong>
                    {'Subscribe'}
                </strong>
            ),
            width: 200,
            sortable: true,
            renderCell: (params) => {
                // Check if the author of the question list is not the logged-in user
                const isNotLoggedInUserQuestionList = params.row.creatorId !== loggedInUserId;

                // Render the subscribe button if the conditions are met
                if (isNotLoggedInUserQuestionList) {
                    return (
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation(); // Otherwise you would automatically go to the question details page
                            handleSubscribe(params.row.id);
                        }}>
                            Subscribe
                        </Button>
                    );
                } else {
                    return null; // Render nothing if the conditions are not met
                }
            },
            align: 'left',

        },
    ];


    const handleRowClick = (params: any, isPublic: boolean) => {
        console.log("Clicked row data:", params.row);
        // Redirect to detail page with the ID of the clicked row
        navigate(`/progression_paths/details?id=${params.row.id}&Subscribed=${!isPublic}`);
    };


    const publicUrl = process.env.REACT_APP_API_URL + '/progression-paths/all';
    const subscribedUrl = process.env.REACT_APP_API_URL + '/progression-paths/';
    useEffect(() => {
        // fetchPublicPaths(publicUrl);
        // fetchSubscribedPaths(subscribedUrl);
        //Fetch all progression paths
        fetch(publicUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the data", data);
                setPublicPathList(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the public progression paths:', error);
            });

        //Fetch subscribed progression paths
        fetch(subscribedUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("this is the data", data);
                setPathList(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the subscribed progression paths:', error);
            });

    }, [subscribedUrl, publicUrl]);

    useEffect(() => {
        // Fetch author names for all path entries
        const authorIds = Array.from(new Set([
            ...pathList.map(path => path.creatorId),
            ...publicPathList.map(path => path.creatorId)
        ]));
        const uniqueAuthorIds = Array.from(new Set(authorIds)); // Get unique author IDs
        console.log(uniqueAuthorIds)
        const fetchAuthorNames = async () => {
            for (const authorId of uniqueAuthorIds) {
                const urlUser = `${process.env.REACT_APP_API_URL}/users/${authorId}`;
                fetch(urlUser, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log("this is the user data", data);
                        setAuthorNames(prevState => ({
                            ...prevState,
                            [authorId]: data.firstName + ' ' + data.lastName
                        }));

                    })
                    .catch(error => {
                        // Handle error
                        console.error('There was a problem with the retrieval of the user data:', error);
                    });
            }
        };
        fetchAuthorNames();
    }, [pathList, publicPathList]);

    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    mt: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Typography color="text.primary">ProgressionPaths</Typography>
                </Breadcrumbs>
                <Grid container justifyContent="space-between">

                    <Typography variant="h3">ProgressionPaths</Typography>
                    <Button
                        variant="contained"
                        size="medium"
                        sx={{ marginRight: 1 }}
                        startIcon={<AddIcon />}
                        onClick={handleManualClick}
                    >
                        Add new progressionPath
                    </Button>


                </Grid>

                <MakeProgressionPathModal modalOpen={modalOpen} handleClose={() => setModalOpen(false)}></MakeProgressionPathModal>

                <Typography variant="h4" sx={{ mt: 5, width: '100%' }}>Subscribed Progression Paths</Typography>
                <Box sx={{ mt: 2, width: '100%' }}>

                    {pathList.length > 0 ? (
                        <DataGrid
                            rows={pathList}
                            columns={ownColumns}

                            onRowClick={(params) => handleRowClick(params, false)}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            slots={{ toolbar: GridToolbar }}

                        />
                    ) : (
                        <p>It looks kind of empty here. Quickly subscribe to a progression path or make one.</p>
                    )}
                </Box>

                <Typography variant="h4" sx={{ mt: 5, width: '100%' }}>All Progression Paths</Typography>

                <Box sx={{ mt: 2, width: '100%' }}>
                        <DataGrid
                            rows={publicPathList}
                            columns={publicColumns}

                            onRowClick={(params) => handleRowClick(params, true)}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            slots={{ toolbar: GridToolbar }}

                        />
                    
                </Box>
                <CustomBottomNavigation value={3} />
            </Box>
        </Container>
    );
};

export default ProgressionPath;
