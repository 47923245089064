import React, { useState } from 'react';
import { Typography, Button, CardContent, Card, Collapse, CardActions, IconButton, IconButtonProps, styled } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Question } from '../interfaces/Question';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


interface Props {
  question: Question;
  handleEdit: (question: Question) => void;
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>
  showButtons: boolean;
}

const QuestionCard: React.FC<Props> = ({ question, handleEdit, setQuestions, showButtons }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded)
  }
  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (window.confirm("Are you sure you want to delete this question?")) {
      // Proceed with delete action
      const url = `${process.env.REACT_APP_API_URL}/question-lists/${question.questionListId}/questions/${question.id}`;
      console.log(url)
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== question.id));
          console.log('deleting question was successful');

        })
        .catch(error => {
          // Handle error
          console.error('There was a problem with the deletion:', error);
        });
    }
  }
  const onEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    console.log("Editing question");
    handleEdit(question);
  }
  return (

    <Card sx={{ borderRadius: 2, boxShadow: 1 }} variant='outlined'>
      <CardContent >
        {showButtons && (<div style={{ position: 'relative' }}>
          <Button onClick={onEditClick} variant="outlined" size="small" sx={{ marginLeft: 1, position: 'absolute', top: 0, right: 0 }} endIcon={<EditIcon />}>Edit</Button>
          <Button onClick={onDeleteClick} variant="outlined" size="small" sx={{ marginLeft: 1, position: 'absolute', top: 0, right: 80 }} endIcon={<DeleteForever />}>Delete</Button>
        </div>)}
        <Typography variant="h5" component="div" sx={{ mr: 25 }}>
          {question.questionText}          </Typography>
        {/* <Box>
          <Typography
            sx={{
              display: "inline-block",
              padding: "0em 0.5em",
              borderRadius: 1,
              color: "white",
              backgroundColor: question.questionAnswerTrue ? "green" : "red",
            }}
          >
            {question.questionAnswerTrue ? "true" : "false"}
          </Typography>
        </Box> */}

        <CardActions disableSpacing>
        <Typography
            sx={{
              display: "inline-block",
              padding: "0em 0.5em",
              borderRadius: 1,
              color: "white",
              backgroundColor: question.questionAnswerTrue ? "green" : "red",
            }}
          >
            {question.questionAnswerTrue ? "true" : "false"}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph>Explanation:</Typography> */}
          <Typography paragraph>
            {question.questionExplanation}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default QuestionCard;
