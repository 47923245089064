import {
    Container,
    CssBaseline,
    Box,
    Typography,
    Grid,
    Breadcrumbs, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import QuestionCard from "../components/QuestionCard";
import '../styles.css';
import CustomBottomNavigation from "../components/CustomBottomNavigation";
import EmptyQuestionCard from "../components/EmptyQuestionCard";
import { Question } from "../interfaces/Question";
import MakeQuestionLogic from "../components/MakeQuestionLogic";
import EditQuestionLogic from "../components/EditQuestionLogic";
import { jwtDecode } from "jwt-decode";
import EditIcon from "@mui/icons-material/Edit";



const QuestionDetail: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [question, setQuestion] = useState('');
    const [questionId, setQuestionId] = useState('');
    const [answer, setAnswer] = useState('false');
    const [explanation, setExplanation] = useState('');
    const [allowEdit, setAllowEdit] = useState(false);
    const [questionListName, setQuestionListName] = useState("loading...");
    const [newQuestionListName, setNewQuestionListName] = useState('');
    const [open, setOpen] = useState(false);
    const [listData, setListData] = useState<any>(null);
    const location = useLocation();
    let decodedToken = '';
    let token = localStorage.getItem("token");
    if (token && token !== "undefined") {
        decodedToken = jwtDecode(token);
    }
    const navigate = useNavigate();
    useEffect(() => {
        const jwt = localStorage.getItem('token');
        if (!jwt || jwt === "undefined") {
            navigate('/login'); // Redirect to login page if 'jwt' is not found
        }
    }, [navigate]);

    useEffect(() => {
        //Fetch all questions from current list
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/question-lists/${id}/questions`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the questions from the questionlist", data);
                setQuestions(data);
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });
    }, [location]);

    useEffect(() => {
        //Fetch details of questionlist
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/question-lists/${id}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("these are the details from the questionlist", data);
                setListData(data);
                setQuestionListName(data.questionListName);
                if (data.createdById === decodedToken.sub) {
                    setAllowEdit(true);
                }

            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the retrieval of the question lists:', error);
            });
    }, [location, allowEdit]);

    const handleDelete = () => {
        const isConfirmed = window.confirm('Are you sure you want to delete this question list?');
        if(isConfirmed){
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id');
            const url = `${process.env.REACT_APP_API_URL}/question-lists/${id}`;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    console.log('Question list delete succesfully');
                    navigate('/dashboard');
                })
                .catch(error => {
                    // Handle error
                    console.error('There was a problem with the retrieval of public question lists:', error);
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNameChangeClick = () => {
        setOpen(true);
    }

    const handleNameChange = () => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const url = `${process.env.REACT_APP_API_URL}/question-lists/${id}`;
        const data = {
            id: id,
            questionListName: newQuestionListName,
            createdById: listData.CreatedById,
            createdOn: listData.CreatedOn,
            isPublic: listData.isPublic,
        };
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}` // Add JWT token here
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Handle successful response
                setQuestionListName(newQuestionListName);
                handleClose();
                console.log("Questionlist name succesfully changed");
            })
            .catch(error => {
                // Handle error
                console.error('There was a problem with the change of the name:', error);
            });
    }

    //not used anymore
    // const questionsTest: Question[] = [
    //     {
    //         id: 1,
    //         questionListId: 101,
    //         questionText: "What is the capital of France?",
    //         questionAnswerTrue: true,
    //         questionExplanation: "The capital of France is Paris.",
    //     },
    //     {
    //         id: 2,
    //         questionListId: 101,
    //         questionText: "What is the largest mammal?",
    //         questionAnswerTrue: false,
    //         questionExplanation: "The largest mammal is the blue whale.",
    //     },
    //     {
    //         id: 3,
    //         questionListId: 102,
    //         questionText: "Who wrote 'Hamlet'?",
    //         questionAnswerTrue: true,
    //         questionExplanation: "'Hamlet' was written by William Shakespeare.",
    //     },
    // ];

    const openEditModal = (question: Question) => {
        setAnswer(question.questionAnswerTrue ? "true" : "false");
        setExplanation(question.questionExplanation);
        setQuestion(question.questionText);
        setQuestionId(question.id);
        setEditModalOpen(true);
    }

    const listItems = questions.map((questionItem, index) =>
        <Grid item key={questionItem.id} xs={12}>
            <QuestionCard question={questionItem} handleEdit={openEditModal} setQuestions={setQuestions} showButtons={allowEdit}></QuestionCard>
        </Grid>
    );

    return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    mt: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    mb: 10
                }}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Link
                        color="inherit"
                        to={"/dashboard"}>
                        Questions
                    </Link>
                    <Typography color="text.primary">Question List</Typography>
                </Breadcrumbs>
                <Grid container alignItems="center" justifyContent="space-between">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="h3">{questionListName}</Typography>
                        {allowEdit && (
                            <EditIcon style={{marginLeft: '8px', cursor: 'pointer'}} onClick={handleNameChangeClick}/>
                        )}
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Edit Questionlist name</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="New Questionlist name"
                                type="text"
                                fullWidth
                                value={newQuestionListName}
                                onChange={(e) => setNewQuestionListName(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleNameChange}>Save</Button>
                        </DialogActions>
                    </Dialog>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        style={{backgroundColor: 'red', color: 'white', display: allowEdit ? 'block' : 'none'}}
                    >
                        Delete question list
                    </Button>
                </Grid>
                <Box sx={{mt: 2, width: '100%'}}>
                    <Grid container spacing={2}>
                        {listItems}
                        {allowEdit && (<Grid item key={38} xs={12}>
                            <EmptyQuestionCard handleClick={() => setModalOpen(true)}></EmptyQuestionCard>
                        </Grid>)}
                    </Grid>
                </Box>
            </Box>
            <MakeQuestionLogic modalOpen={modalOpen} handleClose={() => setModalOpen(false)}
                               question={question}
                               setQuestion={setQuestion}
                               answer={answer}
                setAnswer={setAnswer}
                explanation={explanation}
                setExplanation={setExplanation}></MakeQuestionLogic>

            <EditQuestionLogic modalOpen={editModalOpen} handleClose={() => setEditModalOpen(false)}
                question={question}
                setQuestion={setQuestion}
                answer={answer}
                setAnswer={setAnswer}
                explanation={explanation}
                setExplanation={setExplanation}
                questionId={questionId}
                setQuestions={setQuestions}></EditQuestionLogic>
            <CustomBottomNavigation value={2} />

        </Container >

    );
};

export default QuestionDetail;
